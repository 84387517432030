<template>
  <div>
    <catalogue-header v-if="displayHeader" />

    <div class="header-search">
      <div class="bb-container">
        <SearchBar></SearchBar>
      </div>
    </div>

    <div class="pad-3">
      <div class="bb-container">
        <div class="g-catalogo">
          <catalogue-categories-nav />

          <div class="g-catalogo__list">
            <div class="g-catalogo__title">
              <h1 class="g-catalogo__title-header g-catalogo__title-header--search">
                Resultados para: <span>{{ this.$route.query.q }}</span>
              </h1>
            </div>

            <catalogue-filters />
            <applied-filters />

            <div v-if="resultsNotFound" class="search-ups">
              <div class="search-ups__title">
                ¡Ups! No se encontraron resultados para tu búsqueda.
              </div>
              <div class="search-ups__content">
                <span>Prueba nuevamente con diferentes términos</span> o realiza una nueva
                consulta.
              </div>
            </div>

            <catalogue-products
              :parameters="parameters"
              :route="currentRoute"
              v-else
            ></catalogue-products>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CanFilterProducts from "../mixins/CanFilterProducts";
import AppliedFilters from "../../components/AppliedFilters";
import CanGetCategories from "../../Mixins/CanGetCategories";
import SearchBar from "../../components/SearchBar.vue";
import CatalogueCategoriesNav from "../catalogue/components/CatalogueCategoriesNav";
import CatalogueFilters from "../catalogue/components/CatalogueFilters";
import CatalogueProducts from "../catalogue/components/CatalogueProducts";
import CanGetProductsAtServerSide from "../mixins/CanGetProductsAtServerSide";
import DisplayHeader from "../mixins/DisplayHeader";
import CatalogueHeader from "../catalogue/components/CatalogueHeader";

export default {
  name: "SearchPage",
  mixins: [
    DisplayHeader,
    CanFilterProducts,
    CanGetCategories,
    CanGetProductsAtServerSide,
  ],
  components: {
    CatalogueHeader,
    CatalogueProducts,
    CatalogueCategoriesNav,
    CatalogueFilters,
    AppliedFilters,
    SearchBar: SearchBar,
  },
  metaInfo() {
    return {
      title: `${this.title} | HelloBB`,
      meta: [{ name: "description", content: this.title }],
    };
  },
  data() {
    return {
      currentRoute: null,
      orderBy: "price",
      toggleSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      savedOrderBy: "orderBy",
      loadingProducts: "loadingProducts",
    }),
    resultsNotFound() {
      return !this.show && !this.loadingProducts;
    },
    show() {
      if (this.products) {
        return this.products?.length > 0;
      }
      return false;
    },
    title() {
      return this.categories[0] ? this.categories[0].name : "Categories";
    },
    parameters() {
      return {
        text: this.$route.query.q,
        page: 1,
        order: this.orderBy,
      };
    },
  },
  watch: {
    savedOrderBy() {
      this.getProducts();
    },
  },
  methods: {
    getProducts(server = false) {
      this.orderBy = this.savedOrderBy;
      let parameters = { ...this.parameters, ...{ server: server } };
      parameters = this.applyFilters(parameters, false);
      if (this.$clientSide()) {
        window.mixpanel.track("Search", { "Search Term": parameters.text });
        window.mixpanel.people.increment("# of Searches");
      }
      return this.$store.dispatch("getProducts", parameters);
    },
  },
  created() {
    this.currentRoute = this.$route;
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentRoute = to;
    next();
  },
};
</script>
