<template>
  <div class="header-search__search-product">
    <form onsubmit="return false;" class="" v-on:keyup.enter="searchProducts">
      <div class="bb-form-group">
        <input
          type="search"
          maxlength="256"
          name="query"
          placeholder="¿Qué estás buscando?"
          id="search_bar"
          v-model="searchQuery"
          autocomplete="off"
          class="bb-form bb-form--block"
          required
        />
        <div class="bb-form-group__ico" @click="searchProducts()">
          <img src="../assets/img/ico/ico-search.svg" alt="" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchBar",
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      searchState: "searchState",
    }),
  },
  methods: {
    searchProducts() {
      if (this.searchQuery === "") return;
      const q = this.searchQuery;
      this.searchQuery = "";
      if (this.$route.fullPath.includes("/resultados")) {
        const parameters = {
          text: q,
          page: 1,
        };
        this.$store.dispatch("getProducts", parameters);
      }
      this.$router
        .push({
          name: "search",
          query: {
            q: q,
          },
        });
    },
  },
};
</script>
